<template>
  <div id="manageVehicle" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Revise</div>
        <v-btn class="theme-btn-even" @click.stop="addItem()">
          <span class="I-create"></span>
          <span>Add Vehicle</span>
        </v-btn>
      </div>
    </div>    
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="items" hide-default-footer>
          <template v-slot:item.v_register_date="{ item }">
           {{item.v_register_date | yyyyMMddHHmm}}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="b-action">
              <div class="b-btn">
                <v-btn fab class="btn-edit" @click="editItem(item)">
                  <span class="I-edit"></span>
                </v-btn>
                <v-btn fab class="primary btn-delete" @click="deleteItem(item)">
                  <span class="I-bin"></span>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex lg6>
                <v-text-field
                  v-model="formModel.v_license_plate"
                  label="License Plate"
                ></v-text-field>
              </v-flex>
              <v-flex lg6>
                <v-text-field
                  v-model="formModel.v_city"
                  label="City"
                ></v-text-field>
              </v-flex>
              <v-flex lg6>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                  <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="LicenseExpire"
                      label="License Expire"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker v-model="LicenseExpire" @input="menu = false"></v-date-picker>
              </v-menu>
              </v-flex>
              <v-flex lg6>
                  <v-text-field
                      v-model="formModel.v_type_vehicle"
                      label="Type Car"
                  ></v-text-field>
              </v-flex>
              <v-flex lg4>
                  <v-text-field
                      v-model="formModel.v_brand_name"
                      label="Brand Name"
                  ></v-text-field>
              </v-flex>
              <v-flex lg4>
                  <v-text-field
                      v-model="formModel.v_color"
                      label="Color"
                  ></v-text-field>
              </v-flex>
                <v-flex lg4>
                  <v-text-field
                      v-model="formModel.v_fuel"
                      label="Fuel"
                  ></v-text-field>
              </v-flex>
              <v-flex lg6>
                  <v-text-field
                      v-model="formModel.v_cylinder"
                      label="Cylinder"
                  ></v-text-field>
              </v-flex>
              <v-flex lg6>
                  <v-text-field
                      v-model="formModel.v_engine_capacity"
                      label="Engine Eapacity(CC.)"
                  ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn"   @click="closeDialog">Cancel</v-btn>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn"  :loading="loading"  @click="saveToServer">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>Delete "{{formModel[Object.keys(formModel)[1]]}}" ?</v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn"  @click="closeDialog">Cancel</v-btn>
          <v-btn class="ColorTheme-S white--text btn-size theme-btn" :loading="loading" @click="deleteToServer">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  
  </div>
</template>


<script>
import feathersClientVan from "../plugins/feathers-client";
import fillerdate from "../utils/filterdate";

export default {
  data: () => ({
    headers: [
      { value: "v_license_plate", text: "License Plate", sortable: true },
      // { value: "v_city", text: "City", sortable: true },
      { value: "v_brand_name", text: "Brand", sortable: true },
      { value: "v_type_vehicle", text: "type", sortable: true },
      { value: "v_fuel", text: "Fuel", sortable: true },
      // { value: "v_cylinder", text: "Cylinder", sortable: true },
      // { value: "v_engine_capacity", text: "Engine Capacity", sortable: true },
      { value: "v_register_date", text: "Register ExpireDate", sortable: true },
      { text: '', value: 'action', sortable: false }
    ],
    defaultValue: {
      v_license_plate: ""
    },
    query: { $sort: { Id: -1 } },
    LicenseExpire: new Date().toISOString().substr(0, 10),
    menu: false,
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    dialogDelete: false,
    formModel: {},
    mode: "" // มีได้ 2 แบบคือ create กับ edit
  }),
  computed: {
    formTitle() {
      return this.mode === "create" ? "Add" : "Edit";
    }
  },
  async mounted() {
    this.renderUI();
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    async renderUI() {
      try {
        const q = {};
        q.retire = 0
        var res = await feathersClientVan.service("vehicle").find({ query: q });
        this.total = res.total;
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async addItem() {
      this.mode = "create";
      this.formModel = Object.assign({}, this.defaultValue);
      this.dialog = true;
    },

    async editItem(item) {
      this.mode = "edit";
      this.formModel = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      this.formModel = Object.assign({}, item);
      this.dialogDelete = true;
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async saveToServer() {
      // const valid = await this.$validator.validateAll();
      // if (!valid) {
      //   return;
      // }
      this.loading = true;
      if (this.mode === "edit") {
        try {
          let dataEdit = {
            v_register_date : this.LicenseExpire,
            v_city : this.formModel.v_city,
            v_license_plate : this.formModel.v_license_plate,
            v_color : this.formModel.v_color,
            v_brand_name : this.formModel.v_brand_name,
            v_type_vehicle : this.formModel.v_type_vehicle,
            v_cylinder : this.formModel.v_cylinder,
            v_engine_capacity : this.formModel.v_engine_capacity,
            v_fuel : this.formModel.v_fuel,
            mb : "GEM 2",
            md : new Date()
          }
          await feathersClientVan.service("vehicle").patch(this.formModel.v_id,dataEdit)
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          let data = {
            v_register_date : this.LicenseExpire,
            v_city : this.formModel.v_city,
            v_license_plate : this.formModel.v_license_plate,
            v_color : this.formModel.v_color,
            v_brand_name : this.formModel.v_brand_name,
            v_type_vehicle : this.formModel.v_type_vehicle,
            v_cylinder : this.formModel.v_cylinder,
            v_engine_capacity : this.formModel.v_engine_capacity,
            v_fuel : this.formModel.v_fuel,
            cb : "GEM",
            cd : new Date(),
            retire : 0
          }
          await feathersClientVan.service("vehicle").create(data);
          this.renderUI();
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      }
      this.closeDialog();
    },
    async deleteToServer() {
      this.loading = true;
      try {
           let dataEdit = {
            mb : "GEM 3",
            md : new Date(),
            retire : 1
          }
        await feathersClientVan.service("vehicle").patch(this.formModel.v_id,dataEdit)
        this.renderUI();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    }
  }
};
</script> 

